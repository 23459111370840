import { defineStore } from "pinia";

function mapUser(user) {
  if (user && !user.isAuth) {
    user.id = null;
    user.email = "";
    user.termsAccepted = null;
  }

  return user;
}

export const useCottonBureauStore = defineStore("cottonBureau", {
  state: () => ({
    user: mapUser(window.USER),
  }),

  getters: {},

  actions: {
    acceptTermsOfUse() {
      this.user.termsAccepted = true;
    },
  },
});
